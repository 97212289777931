import { convertToHTML } from 'draft-convert';
import { small, strikethrough } from '@/storyDestinations/converter/styles/default';
import { link } from '@/storyDestinations/converter/entities/default';
import {
  paragraph,
  blockquote,
  codeblock,
  unorderedListItem,
  orderedListItem,
  image,
  gallery,
  convertOembed,
  leadcapture,
} from '@/storyDestinations/converter/blocks/yoast';
// Importing separately as one of the exports in default/index.js
// references the window object which is not available inside the web worker.
import divider from '@/storyDestinations/converter/blocks/default/divider';
import empty from '@/storyDestinations/converter/blocks/default/empty';
import headerFour from '@/storyDestinations/converter/blocks/default/headerFour';
import headerOne from '@/storyDestinations/converter/blocks/default/headerOne';
import headerThree from '@/storyDestinations/converter/blocks/default/headerThree';
import headerTwo from '@/storyDestinations/converter/blocks/default/headerTwo';
import { button } from '@/storyDestinations/converter/blocks/default';

export default function yoastSeoConverter(contentState) {
  return convertToHTML({
    styleToHTML: (style) => {
      switch (style) {
        case 'SMALL':
          return small();
        case 'STRIKETHROUGH':
          return strikethrough();
        default:
          // Return undefined so defaults will be used for styles we don't define (eg. <strong>).
          return undefined;
      }
    },
    blockToHTML: (block) => {
      switch (block.type) {
        case 'unstyled':
          return paragraph(block);
        case 'header-one':
          return headerOne();
        case 'header-two':
          return headerTwo(block);
        case 'header-three':
          return headerThree(block);
        case 'header-four':
          return headerFour(block);
        case 'blockquote':
          return blockquote(block);
        case 'code-block':
          return codeblock();
        case 'unordered-list-item':
          return unorderedListItem(block);
        case 'ordered-list-item':
          return orderedListItem(block);
        case 'section':
        case 'article':
          return empty();
        case 'atomic':
          switch (block.data.type) {
            case 'divider':
              return divider();
            case 'image':
              return image(block);
            case 'gallery':
              return gallery(block);
            case 'button':
              return button(block);
            case 'embed':
            case 'video':
              return convertOembed(block);
            case 'capture':
              return leadcapture(block);
            default:
              // Fallback for block-undefined
              return empty();
          }
        default:
          // Return undefined so defaults will be used for tags we don't define (eg. <li>).
          return undefined;
      }
    },
    entityToHTML: (entity, originalText) => {
      switch (entity.type) {
        case 'LINK':
          return link(entity);
        default:
          return originalText;
      }
    },
  })(contentState);
}
