import { escape } from 'lodash-es';
import stringifyProps from '@/storyDestinations/converter/utils/stringifyProps';
import getAnchorProps from '@/storyDestinations/converter/utils/getAnchorProps';

export default function image(block) {
  const { alignment, src, alt, href, targetBlank, relNofollow } = block.data;

  if (src === '') {
    return { start: '', end: '' };
  }

  const imgProps = { alt: alt ? escape(alt) : '', src };
  const imageHtml = `<img${stringifyProps(imgProps)} />`;
  const linkProps = getAnchorProps(href, targetBlank, relNofollow);

  let start = `<figure data-key="${block.key}" class="image ${block.data.size} ${alignment}">`;
  start += `<a${stringifyProps(linkProps)}>${imageHtml}</a>`;

  let end = '</figure>';

  if (block.text !== '') {
    start += '<figcaption>';
    end = `</figcaption>${end}`;
  }

  return { start, end };
}
