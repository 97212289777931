import { escape } from 'lodash-es';

export default function gallery(block) {
  const { images } = block.data;
  if (!images || images.length === 0) {
    return { start: '', end: '' };
  }

  let imagesHtml = '';
  images.forEach((image) => {
    const imgAlt = image.alt ? escape(image.alt) : '';

    imagesHtml += `<figure><img alt="${imgAlt}" role="presentation" src="${image.src}" />`;
    if (image.caption && image.caption !== '') {
      imagesHtml += `<figcaption>${escape(image.caption)}</figcaption>`;
    }
    imagesHtml += '</figure>';
  });

  let start = `<div class="gallery strchf-gallery ${block.data.size}">`;
  start += `<div class="strchf-gallery-images">${imagesHtml}</div>`;

  let end = '</div>';

  if (block.text !== '') {
    start += '<figcaption>';
    end = `</figcaption>${end}`;
  }

  return { start, end };
}
