import defaultOembed from '@/storyDestinations/converter/blocks/yoast/oembed/defaultOembed';
import simpleLink from '@/storyDestinations/converter/blocks/yoast/oembed/simpleLink';

export default function convertOembed(block) {
  const { src } = block.data;
  const oembed = block.data.data;

  if (src === '' || (typeof oembed === 'undefined' && src === '')) {
    return { start: '', end: '' };
  }

  switch (block.data.provider) {
    case 'bookmark':
    case 'hubspotForm':
    case 'activeCampaignForm':
    case 'embedSocialForm':
      return simpleLink(block);
    default:
      return defaultOembed();
  }
}
