export default function simpleLink(block) {
  let start = `<p><a href="${block.data.src}" target="_blank" rel="noopener noreferrer"`;
  let end = `>${block.data.data.title || block.data.src}</a></p>`;

  if (block.text !== '') {
    // caption becomes the link title
    start = `${start}title="`;
    end = `"${end}`;
  }

  return { start, end };
}
